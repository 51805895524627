import {Component, OnInit, ElementRef, Renderer2, ViewChild, ChangeDetectorRef, VERSION} from '@angular/core';
import {Conversation} from './conversation';
import {ChatService} from './chat.service';
import * as moment from 'moment';
import {populateTimeList} from '../_widgets/time-picker/time-picker';
// import {TextToSpeechService} from '../_services/text-to-speech.service';
// import {SpeechToTextService} from '../_services/speech-to-text.service';
declare var $;

const CLASSES = {
  'OPEN': 'opened',
  'CLOSE': 'closed'
};
const STATUS = {
  'SENT': 'sent',
  'RECEIVED': 'received'
};
const WIDGETS = {
  'TEXT': 'text',
  'VLIST': 'vlist',
  'HLIST': 'hlist',
  'DATE': 'date',
  'TIME': 'time',
  'CAROUSEL': 'carouselBrowse',
  'TABLE': 'tableCard'
};

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  @ViewChild('launcher') launcher: ElementRef;
  @ViewChild('chatWindow') chatWindow: ElementRef;
  @ViewChild('userInput') userInput: ElementRef;
  @ViewChild('messagesList') messagesList: ElementRef;

  public chatMessages;

  private context: any;
  private input: any;
  private conversation: any = {};
  private result_variable;
  public minDate;
  public showTypingIndicator = false;
  private sessionID;

  public isAudioRecording = false;
  // private SSTObservable;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private cdRef: ChangeDetectorRef,
              // private TTSService: TextToSpeechService,
              // private SSTService: SpeechToTextService,
              private chatService: ChatService) {
    this.chatMessages = [];
    this.minDate = moment();
    this.sessionID = this.uuidv4();
    // TTSService.initialize();
    // SSTService.initialize();
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  ngOnInit() {
  }

  openChat() {
    this.renderer.addClass(this.launcher.nativeElement, CLASSES.OPEN);
    this.renderer.removeClass(this.chatWindow.nativeElement, CLASSES.CLOSE);
  }

  closeChat() {
    this.renderer.removeClass(this.launcher.nativeElement, CLASSES.OPEN);
    this.renderer.addClass(this.chatWindow.nativeElement, CLASSES.CLOSE);
  }

  onLauncherClick() {
    if (this.launcher.nativeElement.className.includes(CLASSES.OPEN)) {
      this.closeChat();
    } else {
      this.openChat();
      const requestMsg = $('.launcher').attr('data-request-msg');
      $('.launcher').removeAttr('data-request-msg');
      if (this.chatMessages.length === 0 || requestMsg) {
        this.input = {
          'text': {
            'text': requestMsg || 'hi',
            'language_code': 'en-us'
          }
        };
        if (requestMsg) {
          this.userInput.nativeElement.textContent = requestMsg;
        }
        this.conversation.query_input = this.input;
        this.conversation.session = this.sessionID;
        this.conversation.query_params = {
          'payload': {
            'source': 'web'
          }
        };
        this.sendMessage(!requestMsg);
      }
    }

    // this.TTSService.playDummyAudio();
  }

  updateScroll() {
    this.messagesList.nativeElement.scrollTop = this.messagesList.nativeElement.scrollHeight;
  }

  onChatUpdate() {
    setTimeout(() => {
      this.cdRef.detectChanges();
      if (this.elRef.nativeElement) {
        let $ele = this.elRef.nativeElement.getElementsByClassName('message--content');
        if ($ele.length) {
          $ele = $ele[$ele.length - 1];
          if ($ele) {
            $ele.scrollIntoView();
          } else {
            this.updateScroll();
          }
        } else {
          this.updateScroll();
        }
      } else {
        this.updateScroll();
      }
    });
  }

  sendServiceCall() {
    const sentMsg = {
      message: [],
      status: STATUS.RECEIVED,
      widget: WIDGETS.TEXT,
      list: undefined,
      class: '',
      misc: undefined,
      img: undefined
    };

    this.showTypingIndicator = true;
    this.onChatUpdate();

    // Service Call
    this.chatService.sendMessage(this.conversation)
      .then(conversation => {
        /*let action, client, widget, variable, receivedText;

        this.showTypingIndicator = false;

        this.conversation = conversation;
        this.context = this.conversation.context;
        this.output = this.conversation.output;

        action = this.context.action;
        client = action && action.client;
        widget = client && client.widget;
        variable = client && client.variable;
        this.result_variable = client && client.result_variable;
        receivedText = this.output.text && this.output.text.join('\n');*/

        // sentMsg.message = receivedText;
        // this.TTSService.convertTextToSpeech(receivedText);
        console.log(conversation);
        this.showTypingIndicator = false;
        let payload: any = {};
        let widget;
        const fulfillmentMessages = conversation.queryResult.fulfillmentMessages &&
          conversation.queryResult.fulfillmentMessages.filter(msg => !msg.platform);
        const receivedText = fulfillmentMessages && fulfillmentMessages.map(msg => msg.text && msg.text.text && msg.text.text[0]);
        sentMsg.message = receivedText ? receivedText : sentMsg.message;
        if (!conversation.queryResult.fulfillmentText) {
          conversation.queryResult.fulfillmentMessages.forEach(element => {
            if (element.hasOwnProperty('payload')) {
              payload = element.payload.web;
            }
          });
          widget = this.getUIElem(Object.keys(payload)) ? this.getUIElem(Object.keys(payload)) : 'text';
          if (payload.simpleResponse) {
            payload.simpleResponse.forEach(element => {
              sentMsg.message.push(element.text);
            });
          }
          console.log(widget + ' at chatComp');
          console.log([payload.basicCard]);
          if (payload.suggestions) {
            sentMsg.misc = payload.suggestions;
          }

          if (payload.image) {
            sentMsg.img = payload.image;
          }
        } else {
          let quickReplies = conversation.queryResult.fulfillmentMessages.filter(msg => msg.quickReplies);
          quickReplies = quickReplies.map(msg => msg.quickReplies && msg.quickReplies.quickReplies);
          quickReplies = quickReplies && quickReplies[0];
          if (quickReplies) {
            sentMsg.misc = quickReplies.map(reply => {
              return {title: reply, payload: reply};
            });
          }
        }

        switch (widget) {
          case 'browseCarousel':
            sentMsg.list = payload.browseCarousel;
            sentMsg.widget = WIDGETS.HLIST;
            break;
          case 'basicCard':
            sentMsg.list = [payload.basicCard];
            sentMsg.widget = WIDGETS.HLIST;
            break;
          case 'selectCarousel':
            sentMsg.list = payload.selectCarousel;
            sentMsg.widget = WIDGETS.HLIST;
            break;
          case 'selectList':
            sentMsg.list = payload.selectList;
            sentMsg.widget = WIDGETS.VLIST;
            break;
          case 'date':
            sentMsg.widget = WIDGETS.DATE;
            break;
          case 'time':
            // sentMsg.list = populateTimeList(this.context[variable]);
            sentMsg.widget = WIDGETS.TIME;
            break;
          case 'buttons':
            sentMsg.list = payload.buttons;
            sentMsg.widget = 'buttons';
            break;
          case 'carouselBrowse':
            sentMsg.list = payload.carouselBrowse.items;
            sentMsg.widget = WIDGETS.CAROUSEL;
            break;
          case 'tableCard':
            sentMsg.list = payload.tableCard;
            sentMsg.widget = WIDGETS.TABLE;
            break;
        }
        this.chatMessages.push(sentMsg);
        this.onChatUpdate();
      }, () => {
        this.showTypingIndicator = false;
        sentMsg.message = ['Something wrong! Please try again.'];
        sentMsg.class = 'error';
        this.chatMessages.push(sentMsg);
        this.onChatUpdate();
      });
  }

  sendMessage(isEmptyMessage: boolean = false) {
    let inputText = this.userInput.nativeElement.textContent;
    inputText = inputText && inputText.trim();
    if (inputText || isEmptyMessage) {
      if (inputText) {
        this.chatMessages.push({
          message: [inputText],
          status: STATUS.SENT,
          widget: WIDGETS.TEXT
        });
        this.onChatUpdate();
        this.input = {
          'text': {
            'text': inputText,
            'language_code': 'en-us'
          }
        };
      }
      this.conversation.query_input = this.input;
      // this.conversation.session = '38auf8347a3';
      // this.conversation.query_params= {
      //   'payload': {
      //     'source': 'web'
      //   }
      // };

      this.sendServiceCall();
    }
    this.userInput.nativeElement.innerHTML = '';
  }

  enterMessage($event) {
    if ($event.keyCode === 13 && !$event.shiftKey) {
      $event.preventDefault();
      this.sendMessage();
    }
  }

  onMessageSelect(value, isVariableInput = false): void {
    if (isVariableInput) {
      if (this.result_variable) {
        this.context[this.result_variable] = value;
      }
    } else {
      if (value) {
        this.chatMessages.push({
          message: [value],
          status: STATUS.SENT,
          widget: WIDGETS.TEXT
        });
        this.onChatUpdate();
      }
      this.input = {
        'text': {
          'text': value,
          'language_code': 'en-us'
        }
      };
      this.conversation.query_input = this.input;
    }
    // Service Call
    this.sendServiceCall();
  }

  /*stopRecording() {
    this.isAudioRecording = false;
    this.cdRef.detectChanges();

    this.SSTService.onRecordingStop();
    this.sendMessage();

    if (this.SSTObservable) {
      this.SSTObservable.unsubscribe();
    }
  }

  recordAudio() {
    this.TTSService.stopAudio();
    if (this.isAudioRecording) {
      this.stopRecording();
      return;
    }

    this.isAudioRecording = true;

    this.SSTObservable = this.SSTService.recordAudio().subscribe((msg) => {
      this.userInput.nativeElement.textContent = msg;
    }, () => { // On Error
      this.stopRecording();
    }, () => { // On Complete
      this.stopRecording();
    });
  }*/

  getUIElem(uiList) {
    uiList = uiList.filter(v => v !== 'simpleResponse');
    uiList = uiList.filter(v => v !== 'suggestions');
    return uiList.toString();
  }
}

import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
    window.vceResetFullWidthElements && window.vceResetFullWidthElements();
  }

  ngAfterViewInit() {
    // Select all links with hashes
    $('a.ntech-nav-link[href*="#"]')
      .click(function(event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
          &&
          location.hostname === this.hostname
        ) {
          // Figure out element to scroll to
          let target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000, function() {
              // Callback after animation
              // Must change focus!
              const $target = $(target);
              $target.focus();
              if ($target.is(':focus')) { // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            });
          }
        }
      });

    // ===== Scroll to Top ====
    $(window).scroll(function() {
      if ($(this).scrollTop() >= 100) {        // If page is scrolled more than 50px
        $('#return-to-top').fadeIn(200);    // Fade in the arrow
      } else {
        $('#return-to-top').fadeOut(200);   // Else fade out the arrow
      }
    });
    $('#return-to-top').click(function() {      // When arrow is clicked
      $('body,html').animate({
        scrollTop : 0                       // Scroll to top of body
      }, 500);
    });
  }

  requestDemo(requestType) {
    let requestMsg;
    switch (requestType) {
      case 'Automotive':
        requestMsg = 'Automotive Demo Request';
        break;
      case 'dental':
        requestMsg = 'Dental Practice Demo Request';
        break;
      case 'contactus':
        requestMsg = 'Contact Us';
        break;
    }
    const $launcher = $('.launcher');
    if (!$launcher.hasClass('opened')) {
      $launcher.attr('data-request-msg', requestMsg);
      $launcher.click();
    } else {
      $('#userInputDiv').text(requestMsg);
      $('.sc-user-input--send-icon-wrapper').click();
    }
  }

}

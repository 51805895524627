import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-blist',
  templateUrl: './blist.component.html',
  styleUrls: ['./blist.component.css']
})
export class BlistComponent implements OnInit {

  @Input() message;
  @Output() select = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onToggle(value, message){
    message.disabled = true;

    this.select.emit(value);
  }

}

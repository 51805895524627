import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatCardModule,
  MatListModule,
  MatRadioModule,
  MatButtonModule,
  MatIconModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatDividerModule
} from '@angular/material';
import { NguCarouselModule } from '@ngu/carousel';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ChatComponent } from './chat/chat.component';
import { ChatService } from './chat/chat.service';
import { ScrollableListComponent } from './_widgets/scrollable-list/scrollable-list.component';
import { DatePickerComponent } from './_widgets/date-picker/date-picker.component';
import { TimePickerComponent } from './_widgets/time-picker/time-picker.component';
import { RadioListComponent } from './_widgets/radio-list/radio-list.component';
import { ChatMessageComponent } from './_widgets/chat-message/chat-message.component';
import { BlistComponent } from './_widgets/blist/blist.component';
import { SuggestionsComponent } from './_widgets/suggestions/suggestions.component';
import { CarouselBrowseComponent } from './_widgets/carousel-browse/carousel-browse.component';
import { TableCardComponent } from './_widgets/table-card/table-card.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ChatComponent,
    ScrollableListComponent,
    DatePickerComponent,
    TimePickerComponent,
    RadioListComponent,
    CarouselBrowseComponent,
    ChatMessageComponent,
    BlistComponent,
    SuggestionsComponent,
    TableCardComponent
  ],
  entryComponents: [
    ChatComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDividerModule,
    NguCarouselModule
  ],
  providers: [ChatService],
  bootstrap: [AppComponent]
})
export class AppModule { }

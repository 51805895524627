import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'll',
  },
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'll',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class DatePickerComponent implements OnInit {

  @Input() message;
  @Input() minDate;

  @Output() select = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  // On Date Selection
  onDateChange($event, message) {
    message.disabled = true;
    const value = $event.value.format(MY_FORMATS.display.dateInput);
    this.select.emit(value);
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss']
})
export class TableCardComponent implements OnInit {

  @Input() message;

  @Output() select = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onItemSelect($event, message) {
    message.disabled = true;
    if ($event.value) {
      this.select.emit($event.value.title);
   } else {
     this.select.emit($event);
   }
  }

  onNavigate(url) {
    window.open(url, '_blank');
  }

}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {

  @Input() message;
  @Input() isLatest;
  @Output() select = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onItemSelect($event, message) {
    message.disabled = true;
    if ($event.value) {
      this.select.emit($event.value.title);
   } else {
     this.select.emit($event);
   }
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'radio-list',
  templateUrl: './radio-list.component.html',
  styleUrls: ['./radio-list.component.scss']
})
export class RadioListComponent implements OnInit {

  @Input() message;

  @Output() select = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onItemSelect($event, message) {
    message.disabled = true;
    if($event.value){
      this.select.emit($event.value.title);
   }
   else{
     this.select.emit($event);
   }
  }

}

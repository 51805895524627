import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class ChatService {
  private chatUrl = environment.chatUrl;
  private headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) {
  }

  sendMessage(conversation: any): Promise<any> {
    return this.http
      .post(this.chatUrl, JSON.stringify(conversation), {headers: this.headers})
      .toPromise()
      .then(res => res as any)
      .catch(this.handleError);

  }

  private handleError(error: any): Promise<any> {
    console.error('Error', error);
    return Promise.reject(error.message || error);
  }

}

import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'scrollable-list',
  templateUrl: './scrollable-list.component.html',
  styleUrls: ['./scrollable-list.component.scss']
})
export class ScrollableListComponent implements OnInit {

  carouselBanner;

  @ViewChild('scrollList') scrollList: ElementRef;

  @Input() message;

  @Output() select = new EventEmitter();

  constructor() { }

  ngOnInit() {
    console.log(this.message);
    this.carouselBanner = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      point: {
        visible: true
      },
      load: 2,
      loop: true,
      touch: true
    };
  }

  nextPage() {
    const scrollEl = this.scrollList.nativeElement;
    scrollEl.scrollLeft = scrollEl.scrollLeft + 100;
  }

  previousPage() {
    const scrollEl = this.scrollList.nativeElement;
    scrollEl.scrollLeft = this.scrollList.nativeElement.scrollLeft - 100;
  }

  onCardSelect(item, value, message) {
    message.disabled = true;

    this.select.emit(item);

    value.active = true;
  }

  onItemSelect($event, message) {
    message.disabled = true;
    if ($event.value) {
      this.select.emit($event.value.title);
      console.log($event.value.title);
   } else {
     this.select.emit($event);
   }
  }

  onNavigate(url) {
    window.open(url, '_blank');
  }
}

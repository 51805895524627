import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.css']
})
export class SuggestionsComponent implements OnInit {

  @Input() message;
  @Output() select = new EventEmitter();
  role = false;
  constructor() { }

  ngOnInit() {
  }

  onSelect($event, message) {
    message.disabled = true;
    this.select.emit($event.payload);
  }

}

import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimePickerComponent implements OnInit {

  @Input() message;

  @Output() select = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  // On Date Selection
  onTimeSelect($event, message) {
    message.disabled = true;
    this.select.emit($event.value);
  }

}
